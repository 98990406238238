@tailwind base;
@tailwind components;
@tailwind utilities;

.ReactModal__Body--open {
  overflow: hidden;
  /* prevents background page from scrolling when the modal is open */
}

.ReactModal__Overlay {
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReactModal__Content {
  background: white;
  width: fit-content;
  max-width: calc(100vw - 2rem);
  max-height: calc(100vh - 2rem);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
  overflow-y: auto;
  padding: 0;
  border-radius: 1.5rem;
  position: relative;
}
